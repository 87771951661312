<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

const router = useRouter()
const route = useRoute()

const drawerToggle = ref(null)


function navTo(dest) {
  router.push(dest);
  drawerToggle.value.checked = false;
}
</script>

<template>

  <input id="my-drawer" ref="drawerToggle" type="checkbox" class="drawer-toggle" />
  <div class="drawer-side" style="z-index: 9999">
    <label for="my-drawer" aria-label="close sidebar" class="drawer-overlay"></label>

    <ul class="menu bg-base-200 text-base-content min-h-full w-80 p-4">
      <div class="text-right">
        <label for="my-drawer" tabindex="0" role="button" class="btn btn-ghost btn-circle drawer-button">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </label>
      </div>

      <!-- Sidebar content here -->
      <li><a @click="navTo('/')">Home</a></li>
      <li><a @click="navTo('/about')">About</a></li>
      <li><a @click="navTo('/scan')">Scan</a></li>
      <li>&nbsp;</li>
      <li><a @click="navTo('/logout')">Logout</a></li>
    </ul>
  </div>


  <div class="navbar bg-primary text-white bg-base-100 nav-sticky">
    <div class="navbar-start">
      <div class="dropdown">
        <label for="my-drawer" tabindex="0" role="button" class="btn btn-ghost btn-circle drawer-button">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
          </svg>
        </label>

      </div>

      <button v-if="route.path != '/'" class="btn btn-ghost pl-0" @click="router.back();">&lt; Back</button>
    </div>

    <div class="navbar-center">
      <a class="btn btn-ghost text-xl">TNXE Driver</a>
    </div>
    <div class="navbar-end">
      <button class="btn btn-ghost btn-circle">
        <div class="indicator">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
          <span class="badge badge-xs badge-primary indicator-item"></span>
        </div>
      </button>
    </div>
  </div>
</template>

<style>
.nav-sticky {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 99 !important;
}
</style>