<template>
    <div id="transaction" class="pb-3">
        <div class="card bg-base-100 shadow-xl mb-3">
            <div class="card-body">
                <h2 class="card-title">BOL: {{ transaction.bolno }}</h2>
                <p>
                    {{ transaction.pieces }} pcs. / {{ transaction.actwgtlbs }} lbs.
                </p>
            </div>
        </div>
        <billto-block :billto="transaction.billto" />
        <company-block title="Shipper" :company="transaction.shipper" />
        <company-block title="Consignee" :company="transaction.consignee" />

        <div class="card bg-base-100 shadow-xl mb-3">
            <div class="card-body">
                <h2 class="card-title">Special Instructions</h2>
                <p>
                    {{ transaction.specinstructions }}
                </p>
            </div>
        </div>

        <div v-if="authStore.driverIdIsUser(transaction.delivery?.driverid)">
            <delivery-block :transaction="transaction" />
        </div>

        <div v-if="authStore.driverIdIsUser(transaction.pickup?.driverid)">
            <pickup-block :transaction="transaction" />
        </div>
    </div>
</template>

<script setup>
import { useTransactionsStore } from '@/stores/transactions';
import { useAuthStore } from '@/stores/auth';

import { onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import BilltoBlock from '@/components/Transaction/BilltoBlock.vue'
import CompanyBlock from '@/components/Transaction/CompanyBlock.vue'
import PickupBlock from '@/components/Transaction/PickupBlock.vue'
import DeliveryBlock from '@/components/Transaction/DeliveryBlock.vue'


const route = useRoute();
const router = useRouter();

const transactionsStore = useTransactionsStore();
const authStore = useAuthStore();

let transaction = transactionsStore.transactions[route.params.driverid].manifests[route.params.manifestid].transactions.find((t) => {
    return t.transactionid == route.params.transactionid;
});


function rowClick(tr) {
    console.log(tr, window.navigator.onLine)
}



</script>
