import { defineStore } from "pinia"
import { useAuthStore } from "./auth"


export const useTransactionsStore = defineStore('transactions', {
    state() {
        return {
            minRoot: import.meta.env.VITE_MIN_ROOT,
            transactions: {}
        }

    },
    actions: {
        async loadTransactions() {
            let driverid, api_url, res, data;
            const authStore = useAuthStore();


            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + authStore.lat)

            api_url = this.minRoot + '/api/1.1/pwa/user/driver-data'
            res = await fetch(api_url, {
                method: 'GET',
                headers: myHeaders
            });
            data = await res.json();
            this.transactions = data;
        },

        $reset() {
            this.transactions;
        }

    },
    getters() {

    },
    persist: true
});