<template>
    <div id="pickup">
        <h2>Pickup Details</h2>
        {{ transaction }}
    </div>
</template>

<script setup>
import { useTransactionsStore } from '@/stores/transactions';
import { useAuthStore } from '@/stores/auth';

import { useRoute, useRouter } from "vue-router";


const route = useRoute();
const router = useRouter();

const transactionsStore = useTransactionsStore();
const authStore = useAuthStore();

let transaction = transactionsStore.transactions[route.params.driverid].manifests[route.params.manifestid].transactions.find((t) => {
    return t.transactionid == route.params.transactionid;
});



</script>
