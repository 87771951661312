<template>
    <div class="flex justify-center">
        <div class="card bg-base-100 shadow-xl mt-20 mb-20">
            <div class="card-body">
                <h2 class="card-title">Login</h2>

                <div class="col-12" v-if="Object.keys(validationErrors).length > 0">
                    <div class="alert alert-danger">
                        <ul class="mb-0">
                            <li v-for="(value, key) in validationErrors" :key="key">{{ value[0] }}</li>
                        </ul>
                    </div>
                </div>
                <form onsubmit="return false;">
                    <div class="items-center mt-2">
                        <div>Email or Phone:</div>
                        <label class="input input-bordered flex items-center gap-2 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                class="w-4 h-4 opacity-70">
                                <path
                                    d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                                <path
                                    d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                            </svg>
                            <input type="text" class="grow" v-model="auth.emailaddr" name="emailaddr" id="emailaddr" />
                        </label>
                        <div class="mt-4">Password:</div>
                        <label class="input input-bordered flex items-center gap-2 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                class="w-4 h-4 opacity-70">
                                <path fill-rule="evenodd"
                                    d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <input type="password" class="grow" value="password" v-model="auth.password" name="password"
                                id="password" />
                        </label>
                    </div>
                    <div class="card-actions justify-end pt-4">
                        <button class="btn btn-primary w-full" @click="login">{{ processing ? "Please wait" : "Login"
                            }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useAuthStore } from '@/stores/auth';
import { useTransactionsStore } from '@/stores/transactions';
import { useRouter } from 'vue-router'



const authStore = useAuthStore();
const transactionsStore = useTransactionsStore();
const minRoot = inject('minRoot')

const router = useRouter()

let processing = ref(false);
let validationErrors = ref({})

let auth = {
    emailaddr: null,
    password: null,
    device_name: navigator.userAgent
}

async function login() {
    processing.value = true

    //try {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    let resp = await fetch(minRoot + '/api/1.1/login', {
        method: 'POST',
        body: JSON.stringify(auth),
        headers: myHeaders
    })
    let data = await resp.json();
    if (resp.status !== 200) {
        validationErrors.value = data.errors;
    } else {
        validationErrors.value = {}
        processing.value = false;
        signIn(data);
    }
    /* }
     catch(e) {
         validationErrors.value = {'emailaddr': [e]}
         processing.value = false;
     }*/

}

async function signIn(data) {
    let token = data.token;
    localStorage.setItem("lat", token);

    authStore.lat = token;
    authStore.emailphone = auth.emailaddr;
    authStore.loggedin = true;
    authStore.drivers = data.drivers;
    authStore.userid = data.userid;

    await transactionsStore.loadTransactions();

    router.replace({ path: '/' })

}

</script>