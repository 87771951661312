import { defineStore } from "pinia"
import { ref } from "vue"

export const useAuthStore = defineStore('auth', {
    state() {
        return {
            emailphone: null,
            loggedin: false,
            userid: null,
            drivers: [],
            lat: null
        }
    },
    actions: {
        $reset() {
            this.emailphone = null;
            this.loggedin = false;
            this.userid = null;
            this.drivers = [];
            this.lat = null;
        },
        driverIdIsUser(driverid) {
            return Object.keys(this.drivers).includes(String(driverid));
        }
    },
    getters: {

    },
    persist: true
});