<template>
    <div class="card bg-base-100 shadow-xl mb-3">
        <div class="card-body">
            <h2 class="card-title">{{ title }}</h2>
            <p>
                {{ company.coname }}<br>
                {{ company.address1 }}<br>
                <span v-if="company.address2 != ''">
                    {{ company.address2 }}<br>
                </span>
                {{ company.city }}, {{ company.state }} {{ company.zip }}
            </p>
            <div class="card-actions justify-center mt-1">
                <a :href="mapLink" class="btn btn-primary btn-block" target="_blank">Map</a>
            </div>

        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const { company, title } = defineProps(['company', 'title'])

const mapLink = computed(() => {
    return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(company.coname + ' ' + company.address1 + ' ' + company.address2 + ' ' + company.city + ', ' + company.state + ' ' + company.zip);
})

</script>