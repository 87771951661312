<template>
    <div class="card bg-base-100 shadow-xl mb-3">
        <div class="card-body">
            <h2 class="card-title">Pickup</h2>
            <p>
            <div>
                PU Date: {{ DateTime.fromISO(transaction.pickup.puddate).toLocaleString() }}
            </div>
            <div v-if="transaction.pickup.readytime">
                Ready Time: {{ transaction.pickup.readytime }}
            </div>
            <div>
                PU Confirmed?: {{ (transaction.pickup.puconf) ? 'YES' : 'NO' }}
            </div>
            <div v-if="transaction.pickup.intime">
                In Time: {{ transaction.pickup.intime }}
            </div>
            <div v-if="transaction.pickup.outtime">
                Out Time: {{ transaction.pickup.outtime }}
            </div>
            </p>
            <div class="card-actions justify-center mt-1">
                <a :href="route.path + '/pickup'" class="btn btn-primary btn-block">Enter Pickup Details</a>
            </div>

        </div>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();

defineProps(['transaction'])

</script>