<script setup>
import { ref } from "vue";
import VideoNormalizer from "@/components/VideoNormalizer.vue";

const mode = ref("video");

function receivePassedImage(bytes) {
    console.log(bytes);
}
</script>
<template>
    <div class="scan">
        <h1>Scan</h1>
        <VideoNormalizer @passedImage="receivePassedImage" /> 

    </div>
</template>


<style scoped>

</style>