import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home.vue'
import About from '@/pages/About.vue'
import Scan from '@/pages/Scan.vue'
import Login from '@/pages/Login.vue'
import Logout from '@/pages/Logout.vue'
import Transaction from '@/pages/Transaction.vue'
import Manifest from '@/pages/Manifest.vue'
import { useAuthStore } from '@/stores/auth'
import DeliveryDetails from './pages/DeliveryDetails.vue'
import PickupDetails from './pages/PickupDetails.vue'

let router = createRouter({
    history: createWebHistory(),
    routes: [
        //{ path: '/manifest/:driverid/:manifestid/:transactionid/:transtype/add-document', name: 'Add Scanned Doc', component: AddDocument, meta: { middleware: "auth", title: "Transaction" } },
        //{ path: '/manifest/:driverid/:manifestid/:transactionid/:transtype', name: 'Pickup/Delivery Details', component: PickupOrDeliveryDetails, meta: { middleware: "auth", title: "Transaction" } },
        {
            path: '/manifest/:driverid/:manifestid/:transactionid/delivery',
            name: 'Delivery Details',
            component: DeliveryDetails,
            meta: {
                middleware: "auth",
                title: "Delivery Details",
                transition: 'slide-left'
            }
        },
        {
            path: '/manifest/:driverid/:manifestid/:transactionid/pickup',
            name: 'Pickup Details',
            component: PickupDetails,
            meta: {
                middleware: "auth",
                title: "Pickup Details",
                transition: 'slide-left'
            }
        },
        {
            path: '/manifest/:driverid/:manifestid/:transactionid',
            name: 'Transaction',
            component: Transaction,
            meta: {
                middleware: "auth",
                title: "Transaction",
                transition: 'slide-left'
            }
        },
        {
            path: '/manifest/:driverid/:manifestid',
            name: 'Manifest',
            component: Manifest,
            meta: {
                middleware: "auth",
                title: "Manifest",
                transition: 'slide-left'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                middleware: "guest",
                title: "Login",
                transition: 'slide-left'
            }
        },
        {
            path: '/about',
            name: 'About',
            component: About,
            meta: {
                middleware: "auth",
                transition: 'slide-left'
            }
        },
        {
            path: '/scan',
            name: 'Scan',
            component: Scan,
            meta: {
                middleware: "auth",
                transition: 'slide-left'
            }
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: {
                middleware: "auth",
                title: "Logout",
                transition: 'slide-left'
            }
        },
        {
            path: '/', name: 'Home',
            component: Home,
            meta: {
                middleware: "auth",
                transition: 'slide-left'
            }
        },
        // {
        //     path: '/index.html',
        //     redirect: '/',
        // },
        // {
        //     path: 'assets/index.html',
        //     redirect: '/',
        // },
    ]
})


router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    console.log("from", from);
    console.log("to", to);
    document.title = to.meta.title ?? 'Driver App'
    if (to.meta.middleware == "guest") {
        if (authStore.loggedin) {
            next({ name: "Home" })
        }
        next()
    } else {
        console.log("loggedin", authStore.loggedin);

        if (authStore.loggedin) {
            next()
        } else {
            next({ name: "Login" })
        }
    }
})

router.afterEach((to, from) => {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    if (to.path == from.path) {
        to.meta.transition = 'fade'
    } else {
        to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }

})

export {
    router
}
