<template>
    <div class="card bg-base-100 shadow-xl mb-3">
        <div class="card-body">
            <h2 class="card-title">Delivery</h2>
            <p>
            <div>
                Del Date: {{ DateTime.fromISO(transaction.delivery.puddate).toLocaleString() }}
            </div>
            <div v-if="transaction.delivery.intime">
                In Time: {{ transaction.delivery.intime }}
            </div>
            <div v-if="transaction.delivery.outtime">
                Out Time: {{ transaction.delivery.outtime }}
            </div>
            <div v-if="transaction.pod">
                POD Signature: {{ transaction.pod }}
            </div>
            <div v-if="transaction.poddate && transaction.podtime">
                POD Date/Time: {{ DateTime.fromISO(transaction.poddate).toLocaleString() + ' ' + transaction.podtime }}
            </div>
            </p>
            <div class="card-actions justify-center mt-1">
                <a :href="route.path + '/delivery'" class="btn btn-primary btn-block">Enter Delivery Details</a>
            </div>


        </div>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { DateTime } from "luxon";

const route = useRoute();

defineProps(['transaction'])


</script>