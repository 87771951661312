<template>
    <div>
        <div id="manifest" class="px-3">
            <h1>Manifest {{ route.params.manifestid }}</h1>
        </div>
        <div class="flex flex-col text-gray-900 bg-white bg-clip-border">
            <nav class="flex min-w-[240px] flex-col gap-1 p-2 text-base">
                <div role="button"
                    class="flex items-center p-3 leading-tight transition-all outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 border-b border-gray-300"
                    v-for="(value, index) in transactionsStore.transactions[route.params.driverid].manifests[route.params.manifestid].transactions"
                    @click="rowClick(value)">
                    <div class="flex grow">
                        {{ value.bolno }}
                    </div>
                    <div class="flex justify-end ml-2">
                        <ChevronRightIcon class="size-6 text-black" />
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script setup>
import { useTransactionsStore } from '@/stores/transactions';
import { useRoute, useRouter } from "vue-router";
import { ChevronRightIcon } from '@heroicons/vue/24/solid'

const route = useRoute();
const router = useRouter();
const transactionsStore = useTransactionsStore();


function rowClick(tr) {
    console.log(tr)
    router.push({ path: '/manifest/' + route.params.driverid + '/' + route.params.manifestid + '/' + tr.transactionid });
}

</script>
