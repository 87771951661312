<template>
    <div class="about">
        <h1>About</h1>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';

    onMounted(async () => {
        /*
        // No auth
        let resp = await fetch('https://mlester.laravel.transportltl.com/api/1.1/driver/319');
        let data = await resp.json();
        console.log(data);
        */


    });
    
</script>