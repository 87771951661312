<template>
    <div class="flex justify-center">
        <div class="card bg-base-100 shadow-xl mt-20 mb-20">
            <div class="card-body">
                <h2 class="card-title">Logout</h2>

                <p>Are you sure?</p>
                <button class="btn btn-error" @click="logout">Confirm</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { useTransactionsStore } from '@/stores/transactions';
import { useRouter } from 'vue-router'



const authStore = useAuthStore();
const transactionsStore = useTransactionsStore();

const router = useRouter()

function logout() {
    authStore.$reset();
    transactionsStore.$reset();
    localStorage.removeItem("lat")
    router.replace({ path: '/' })
}
</script>