<template>
    <div id="delivery-details" class="pb-3 m-2">
        <div class="card bg-base-100 shadow-xl mb-3">
            <div class="card-body">
                <h2 class="card-title">BOL: {{ transaction.bolno }}</h2>
                <p>
                    {{ transaction.pieces }} pcs. / {{ transaction.actwgtlbs }} lbs.
                </p>
            </div>
        </div>

        <div v-if="transaction.delivery.intime == ''">
            <button class="btn btn-primary btn-block" @click="recordIn">Record Delivery In</button>
        </div>
        <div v-else class="card bg-base-100 shadow-xl mb-3">
            <div class="card-body">
                <h2 class="card-title">Delivery In</h2>
                <p>
                    In Time: {{ transaction.delivery.intime }}
                </p>
            </div>
        </div>

        <div v-if="transaction.delivery.intime != ''">
            <div v-if="!DateTime.fromISO(transaction.poddate).isValid">
                <input type="text" v-model="podSig" placeholder="POD Signature Name"
                    class="input input-bordered input-primary w-full mb-2" />
                <button class="btn btn-primary btn-block" @click="recordOut">Record Delivery Out</button>
            </div>
            <div v-else class="card bg-base-100 shadow-xl mb-3">
                <div class="card-body">
                    <h2 class="card-title">Delivery Out</h2>
                    <p>
                        Out Time: {{ transaction.delivery.outtime }}<br>
                        POD: {{ transaction.pod }}<br>
                        POD Date: {{ DateTime.fromISO(transaction.poddate).toLocaleString() }}<br>
                        POD Time: {{ transaction.podtime }}

                    </p>
                </div>
            </div>
        </div>

        {{ transaction }}


    </div>
</template>

<script setup>
import { useTransactionsStore } from '@/stores/transactions';
import { useAuthStore } from '@/stores/auth';
import { ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { DateTime } from "luxon";


const route = useRoute();
const router = useRouter();

const transactionsStore = useTransactionsStore();
const authStore = useAuthStore();

let transaction = transactionsStore.transactions[route.params.driverid].manifests[route.params.manifestid].transactions.find((t) => {
    return t.transactionid == route.params.transactionid;
});

let podSig = ref('');

function recordOut() {
    //add item to queue
    const dt = DateTime.now()

    console.log("before", transaction.delivery.outttime)
    console.log(dt.toLocaleString(DateTime.TIME_24_SIMPLE))
    transaction.delivery.outtime = dt.toLocaleString(DateTime.TIME_24_SIMPLE)
    console.log("after", transaction.delivery.outttime)
    transaction.pod = podSig
    transaction.podtime = dt.toLocaleString(DateTime.TIME_24_SIMPLE)
    transaction.poddate = dt.toFormat("yyyy-MM-dd")

}


function recordIn() {
    //add item to queue
    const dt = DateTime.now()

    transaction.delivery.intime = dt.toLocaleString(DateTime.TIME_24_SIMPLE)

}

</script>
